import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {Container, Page, Intro} from '../styles'
import DividerPanda from '../components/DividerPanda'

const NotFoundPage = ({
    data: {
        markdownRemark: {
            frontmatter: {title, intro},
        },
    },
}) => (
    <Layout>
        <Page>
            <Container>
                <Intro>
                    <h1>{title}</h1>
                    <p>{intro}</p>
                </Intro>
            </Container>
            <DividerPanda />
        </Page>
    </Layout>
)

export default NotFoundPage

export const query = graphql`
    {
        markdownRemark(frontmatter: {id: {eq: "404"}}) {
            frontmatter {
                title
                intro
            }
        }
    }
`
